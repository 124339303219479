<template>
  <v-hover v-slot:default="{ hover }">
    <v-badge class="d-block" color="none" overlap offset-y="45%" :value="hover">
      <template v-slot:badge>
        <v-btn
          class="grey lighten-4"
          color="grey darken-1"
          icon
          @click="remove()"
        >
          <v-icon size="20px">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <v-card class="" flat>
        <v-container class="py-1">
          <v-row>
            <v-col class="pl-0 py-0" cols="4">
              <v-select
                v-model="selectedAttribute"
                dense
                hide-details
                :items="attributes"
                item-text="name"
                item-value="db"
                :menu-props="{ nudgeTop: '-40px' }"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Thuộc tính"
                return-object
                @change="updateSelectedAttribute($event)"
              ></v-select>
            </v-col>
            <v-col class="pl-0 py-0" cols="4">
              <v-select
                v-model="selectedCondition"
                dense
                hide-details
                :items="selectedAttribute ? selectedAttribute.dk : []"
                item-text="name"
                item-value="op"
                :menu-props="{ nudgeTop: '-40px' }"
                no-data-text="Vui lòng chọn thuộc tính"
                outlined
                placeholder="Điều kiện"
                return-object
                @change="updateSelectedCondition()"
              ></v-select>
            </v-col>
            <v-col class="pl-0 py-0" cols="4">
              <v-autocomplete
                v-if="selectedAttribute && selectedAttribute.list"
                v-model="val"
                class="tp-filter-autocomplete"
                clearable
                :items="
                  selectedAttribute && selectedAttribute.list
                    ? selectedAttribute.list
                    : []
                "
                dense
                deletable-chips
                hide-selected
                hide-details
                item-text="name"
                item-value="id"
                no-data-text="Không có dữ liệu"
                outlined
                single-line
                placeholder="Chọn giá trị"
                @change="updateValue()"
              ></v-autocomplete>
              <v-text-field
                v-else
                v-model="val"
                class="text-body-1"
                clearable
                dense
                hide-details
                outlined
                placeholder="Giá trị"
                @input="updateValue()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-badge>
  </v-hover>
</template>

<script>
export default {
  props: {
    attributes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      selectedAttribute: null,
      selectedCondition: null,
      val: null
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.setAttribute(this.value.db);
        this.selectedCondition = this.value.dk;
        this.val = this.value.list;
      }
    }
  },
  async created() {
    if (this.value) {
      await this.setAttribute(this.value.db);
      this.selectedCondition = this.value.dk;
      this.val = this.value.list;
    }
  },
  methods: {
    async setAttribute(db) {
      const res = await this.attributes.filter(item => item.db === db);

      this.selectedAttribute = res[0];
    },
    remove() {
      this.$emit("remove");
    },
    updateSelectedAttribute() {
      this.$emit("change:name", this.selectedAttribute.name);
      this.$emit("change:db", this.selectedAttribute.db);
      if (this.selectedAttribute.type) {
        this.$emit("change:type", this.selectedAttribute.type);
      }
    },
    updateSelectedCondition() {
      this.$emit("change:condition", this.selectedCondition);
    },
    updateValue() {
      this.$emit("change:value", this.val);
    }
  }
};
</script>
